var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sorry" },
    [
      _c("img", { attrs: { src: require("@/assets/sorry.png") } }),
      _c("span", { staticClass: "text" }, [_vm._v("抱歉，请获取完整版")]),
      _c(
        "Button",
        {
          attrs: {
            to: "http://xpay.exrick.cn/pay?xboot",
            target: "_blank",
            type: "error",
            icon: "md-paper-plane",
          },
        },
        [_vm._v("立即获取")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }